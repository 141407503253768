@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  src: url('./assets/fonts/Poppins-Regular.ttf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Poppins-Regular.ttf') format('woff2'),
  url('./assets/fonts/Poppins-Regular.ttf') format('woff'),
  url('./assets/fonts/Poppins-Regular.ttf') format('truetype'),
  url('./assets/fonts/Poppins-Regular.ttf') format('svg');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #101010;
}

.event-name {
  margin-top: 16px;
  padding: 4px 8px;
  color: #9BC0B1;
  font-size: 1.2em;
  font-family: 'Poppins-Regular';
}

.event-stage {
  margin-bottom: 16px;
  padding: 4px 8px;
  color: #9BC0B195;
  font-size: 0.85em;
  font-family: 'Poppins-Regular';
}

.result-container {
  overflow: hidden;
}

.header-container {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 10% 60% 30%;
  color: #ddd;
  border-bottom: 1px solid #E8887380;
}

.header-index {
  text-align: left;
  font-size: 1em; 
  font-family: 'Poppins-Regular';
}

.header-driver {
  text-align: left;
  font-size: 0.85em;
  font-family: 'Poppins-Regular';
}

.header-result {
  text-align: right;
  font-size: 0.85em;
  font-family: 'Poppins-Regular';
}

.entry-container {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 10% 60% 30%;
  color: #ddd;
  border-bottom: 1px solid #9BC0B180;
}

.entry-index {
  text-align: left;
  font-size: 1em;
  font-family: 'Poppins-Regular';
}

.entry-driver {
  text-align: left;
  font-size: 1em;
  font-family: 'Poppins-Regular';
}

.entry-result {
  text-align: right;
  font-size: 1.5em;
}

.app-description {
  margin: 32px 0;
  padding: 16px 16px;
  color: #999;
  font-family: 'Poppins-Regular';
}

.app-description a:link, .app-description a:visited, .app-description a:active {
  color: #999;
}